<template>
  <v-card>
    <v-card-title>
      <span>タスク 一覧</span>
      <v-spacer></v-spacer>
      <v-btn router to="/main/task/create" height="35px" width="35px" fab>
        <v-icon color="primary">mdi-plus</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text class="pa-0">
      <div class="px-4 pt-4">
        <section>
          <ul class="d-flex align-center mb-3">
            <li class="mr-3" style="width: 180px">
              <v-text-field
                :value="keyword"
                @input="onChangeKeyword"
                label="タスク名"
                placeholder="タスク名を入力"
                prepend-inner-icon="mdi-magnify"
                validate-on-blur
                required
                outlined
                dense
                color="primary"
              ></v-text-field>
            </li>
            <li style="width: 180px">
              <v-text-field
                :value="startDate"
                @click="startDateDialog = true"
                @click:clear="onChangeStart"
                label="開始日"
                placeholder="指定なし"
                prepend-inner-icon="mdi-calendar"
                color="primary"
                readonly
                outlined
                dense
                clearable
              ></v-text-field>
              <v-dialog
                :value="startDateDialog"
                @click:outside="startDateDialog = false"
              >
                <v-date-picker
                  :value="startDate"
                  @change="onChangeStartDate"
                  color="primary"
                  locale="ja"
                ></v-date-picker>
              </v-dialog>
            </li>
            <li>~</li>
            <li style="width: 180px">
              <v-text-field
                :value="endDate"
                @click="endDateDialog = true"
                @click:clear="onChangeEnd"
                label="期限日"
                placeholder="指定なし"
                prepend-inner-icon="mdi-calendar"
                color="primary"
                readonly
                outlined
                dense
                clearable
              ></v-text-field>
              <v-dialog
                :value="endDateDialog"
                @click:outside="endDateDialog = false"
              >
                <v-date-picker
                  :value="endDate"
                  @change="onChangeEndDate"
                  color="primary"
                  locale="ja"
                ></v-date-picker>
              </v-dialog>
            </li>
            <li class="pb-1" style="width: 50px">
              <v-col>
                <v-btn
                  @click="dayAsc()"
                  :class="dayAscBtn"
                  class="mb-1"
                  block
                  style="width: 5px; height: 15px"
                  >昇</v-btn
                >
                <v-btn
                  @click="dayDes()"
                  :class="dayDesBtn"
                  block
                  style="width: 5px; height: 15px"
                  >降</v-btn
                >
              </v-col>
            </li>
          </ul>
        </section>
        <section>
          <ul class="d-flex mb-2">
            <li class="mr-3" style="width: 180px">
              <v-select
                :items="formCategories"
                :value="categoryId"
                @change="onChangeCategory"
                label="案件名"
                item-value="category_id"
                item-text="category_name"
                prepend-inner-icon="mdi-shape"
                outlined
                dense
                color="primary"
                multiple
              >
              </v-select>
            </li>
            <!-- <li class="mr-3" style="width:180px;">
              <v-select :items="formTags" :value="tagId" @change="onChangeTag" label="タグ" item-value="tag_id" item-text="tag_name" prepend-inner-icon="mdi-tag" outlined dense color="primary">
              </v-select>
            </li> -->
            <li class="mr-3" style="width: 180px">
              <v-select
                :items="formUserData"
                :value="requestedId"
                @change="onChangeRequested"
                label="担当者"
                item-value="id"
                item-text="name"
                prepend-inner-icon="mdi-account"
                outlined
                dense
                color="primary"
                multiple
              >
              </v-select>
            </li>
            <li class="mr-3" style="width: 180px">
              <v-select
                :items="formAttributes"
                @change="onAttribute"
                :value="attribute"
                label="担当者属性"
                item-value="value"
                item-text="label"
                prepend-inner-icon="mdi-badge-account-horizontal"
                outlined
                dense
                color="primary"
              >
              </v-select>
            </li>
            <li class="mr-3" style="width: 180px">
              <v-select
                :items="formUserRequesterData"
                :value="requesterId"
                @change="onChangeRequester"
                label="依頼者"
                item-value="id"
                item-text="name"
                prepend-inner-icon="mdi-account"
                outlined
                dense
                color="primary"
                multiple
              ></v-select>
            </li>
            <li
              v-if="requesterId.includes(-1)"
              class="mr-3"
              style="width: 180px"
            >
              <v-text-field
                :value="userKeyword"
                @input="onChangeUserKeyword"
                label="依頼者記入"
                placeholder="依頼者名を入力"
                prepend-inner-icon="mdi-magnify"
                validate-on-blur
                required
                outlined
                dense
                color="primary"
              ></v-text-field>
            </li>
          </ul>
          <ul>
            <li class="d-flex align-center mt-4">
              <p class="mr-2">状態&emsp;：</p>
              <v-btn-toggle
                :value="statusId"
                @change="onChangeStatus"
                multiple
                v-for="status in STATUS"
                :key="status.value"
                :class="status.class"
                class="mr-1"
              >
                <v-btn :value="status.value">
                  {{ status.label }}
                </v-btn>
              </v-btn-toggle>
              <div style="width: 50px">
                <v-col>
                  <v-btn
                    @click="statusAsc()"
                    :class="statusAscBtn"
                    class="mb-1"
                    block
                    style="width: 5px; height: 12px"
                    >昇</v-btn
                  >
                  <v-btn
                    @click="statusDes()"
                    :class="statusDesBtn"
                    block
                    style="width: 5px; height: 12px"
                    >降</v-btn
                  >
                </v-col>
              </div>
            </li>
            <li class="d-flex align-center mb-4">
              <p class="mr-2">優先度：</p>
              <v-btn-toggle
                :value="priorityId"
                @change="onChangePriority"
                multiple
                v-for="priority in PRIORITIES"
                :key="priority.value"
                :class="priority.class"
                class="mr-1"
              >
                <v-btn :value="priority.value">
                  {{ priority.label }}
                </v-btn>
              </v-btn-toggle>
              <div style="width: 50px">
                <v-col>
                  <v-btn
                    @click="priorityAsc()"
                    :class="priorityAscBtn"
                    class="mb-1"
                    block
                    style="width: 5px; height: 12px"
                    >昇</v-btn
                  >
                  <v-btn
                    @click="priorityDes()"
                    :class="priorityDesBtn"
                    block
                    style="width: 5px; height: 12px"
                    >降</v-btn
                  >
                </v-col>
              </div>
            </li>
          </ul>
        </section>
      </div>
      <div class="task_list">
        <div class="d-flex align-end pb-2 px-4">
          <p style="wirth: 250px">
            全 {{ taskData.paginate.total }} 件中
            {{ taskData.paginate.firstItem }} 件 〜
            {{ taskData.paginate.lastItem }} 件を表示
          </p>
          <div style="width: calc(100% - 250px); padding: 0 100px 0 150px">
            <v-pagination
              :value="taskData.paginate.currentPage"
              @input="onClickPaginate"
              :length="taskData.paginate.lastPage"
              color="primary"
              dense
            ></v-pagination>
          </div>
        </div>
        <v-divider></v-divider>
        <TaskList :tasks="taskData.tasks" />
        <Loading v-if="isLoading" />
        <p v-else-if="taskData.tasks === null" class="text-center">
          通信エラー
        </p>
        <p v-else-if="!taskData.tasks.length" class="text-center">
          データはありません
        </p>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import Loading from "@/components/Loading.vue";
import TaskList from "@/components/TaskList.vue";
import { myAxios } from "@/plugins/axios";
import { PRIORITIES } from "@/plugins/const";
import { STATUS } from "@/plugins/const";
import { ATTRIBUTE } from "@/plugins/const";
export default {
  components: {
    TaskList,
    Loading,
  },
  data() {
    return {
      PRIORITIES: PRIORITIES,
      STATUS: STATUS,
      ATTRIBUTE: ATTRIBUTE,
      currentPage: 1,
      priorityId: [0],
      statusId: [0],
      categoryId: [0],
      tagId: 0,
      requestedId: [0],
      attribute: 0,
      requesterId: [0],
      userKeyword: "",
      startDateDialog: false,
      endDateDialog: false,
      startDate: "",
      endDate: "",
      keyword: "",
      taskData: {
        tasks: [],
        paginate: {},
      },
      isLoading: false,
      isStatusAsc: false,
      isStatusDes: false,
      isPriorityAsc: false,
      isPriorityDes: false,
      isDayAsc: false,
      isDayDes: false,
      isOrder: "",
    };
  },
  computed: {
    dayAscBtn() {
      return {
        red: this.isDayAsc,
      };
    },
    dayDesBtn() {
      return {
        blue: this.isDayDes,
      };
    },
    statusAscBtn() {
      return {
        red: this.isStatusAsc,
      };
    },
    statusDesBtn() {
      return {
        blue: this.isStatusDes,
      };
    },
    priorityAscBtn() {
      return {
        red: this.isPriorityAsc,
      };
    },
    priorityDesBtn() {
      return {
        blue: this.isPriorityDes,
      };
    },
    formCategories() {
      return [
        {
          category_id: 0,
          category_name: "全て",
        },
      ].concat(this.$store.state.categories);
    },
    // formTags() {
    //   return [
    //     {
    //       tag_id: 0,
    //       tag_name: "全て",
    //     },
    //   ].concat(this.$store.state.tags);
    // },
    formUserData() {
      return [
        {
          id: 0,
          name: "全て",
        },
      ].concat(this.$store.state.users);
    },
    formUserRequesterData() {
      return [
        {
          id: -1,
          name: "取引先",
        },
        {
          id: 0,
          name: "全て",
        },
      ].concat(this.$store.state.users);
    },
    formAttributes() {
      return [
        {
          value: 0,
          label: "全て",
        },
      ].concat(ATTRIBUTE);
    },
  },
  methods: {
    onAttribute(attribute) {
      this.requestedId = 0;
      this.attribute = attribute;
      this.getTasks();
    },
    dayAsc() {
      if (!this.isDayAsc) {
        this.isDayAsc = true;
        this.isDayDes = false;
        this.isStatusAsc = false;
        this.isStatusDes = false;
        this.isPriorityAsc = false;
        this.isPriorityDes = false;
        this.isOrder = "isDayAsc";
        this.getTasks();
      } else {
        this.isDayAsc = false;
        this.isOrder = "";
        this.getTasks();
      }
    },
    dayDes() {
      if (!this.isDayDes) {
        this.isDayDes = true;
        this.isDayAsc = false;
        this.isStatusAsc = false;
        this.isStatusDes = false;
        this.isPriorityAsc = false;
        this.isPriorityDes = false;
        this.isOrder = "isDayDes";
        this.getTasks();
      } else {
        this.isDayDes = false;
        this.isOrder = "";
        this.getTasks();
      }
    },
    statusAsc() {
      if (!this.isStatusAsc) {
        this.isStatusAsc = true;
        this.isStatusDes = false;
        this.isDayDes = false;
        this.isDayAsc = false;
        this.isPriorityAsc = false;
        this.isPriorityDes = false;
        this.isOrder = "isStatusAsc";
        this.getTasks();
      } else {
        this.isStatusAsc = false;
        this.isOrder = ""; 
        this.getTasks();
      }
    },
    statusDes() {
      if (!this.isStatusDes) {
        this.isStatusDes = true;
        this.isStatusAsc = false;
        this.isDayDes = false;
        this.isDayAsc = false;
        this.isPriorityAsc = false;
        this.isPriorityDes = false;
        this.isOrder = "isStatusDes"
        this.getTasks();
      } else {
        this.isStatusDes = false;
        this.isOrder = "";
        this.getTasks();
      }
    },
    priorityAsc() {
      if (!this.isPriorityAsc) {
        this.isPriorityAsc = true;
        this.isPriorityDes = false;
        this.isDayDes = false;
        this.isDayAsc = false;
        this.isStatusDes = false;
        this.isStatusAsc = false;
        this.isOrder = "isPriorityAsc"
        this.getTasks();
      } else {
        this.isPriorityAsc = false;
        this.isOrder = "";
        this.getTasks();
      }
    },
    priorityDes() {
      if (!this.isPriorityDes) {
        this.isPriorityDes = true;
        this.isPriorityAsc = false;
        this.isDayDes = false;
        this.isDayAsc = false;
        this.isStatusDes = false;
        this.isStatusAsc = false;
        this.isOrder = "isPriorityDes";
        this.getTasks();
      } else {
        this.isPriorityDes = false;
        this.isOrder = "";
        this.getTasks();
      }
    },
    onClickPaginate(page) {
      this.currentPage = page;
      this.getTasks();
    },
    onChangePriority(id) {
      if (id[id.length - 1] === 0) {
        id = [0];
      } else if (id[id.length - 1] === undefined) {
        id = [0];
      } else {
        id = id.filter((x) => x !== 0);
      }
      this.priorityId = id;
      this.getTasks();
    },
    onChangeStatus(id) {
      if (id[id.length - 1] === 0) {
        id = [0];
      } else if (id[id.length - 1] === undefined) {
        id = [0];
      } else {
        id = id.filter((x) => x !== 0);
      }
      this.statusId = id;
      this.getTasks();
    },
    onChangeCategory(id) {
      if (id[id.length - 1] === 0) {
        id = [0];
      } else if (id[id.length - 1] === undefined) {
        id = [0];
      } else {
        id = id.filter((x) => x !== 0);
      }
      this.categoryId = id;
      this.getTasks();
    },
    onChangeTag(id) {
      this.tagId = id;
      this.getTasks();
    },
    onChangeRequested(id) {
      this.attribute = 0;
      if (id[id.length - 1] === 0) {
        id = [0];
      } else if (id[id.length - 1] === undefined) {
        id = [0];
      } else {
        id = id.filter((x) => x !== 0);
      }
      this.requestedId = id;
      this.getTasks();
    },
    onChangeRequester(id) {
      this.userKeyword = "";
      if (id[id.length - 1] === 0) {
        id = [0];
      } else if (id[id.length - 1] === -1) {
        id = [-1];
      } else if (id[id.length - 1] !== -1 && id[0] === -1) {
        id.shift();
      } else if (id[id.length - 1] === undefined) {
        id = [0];
      } else {
        id = id.filter((x) => x !== 0);
      }
      this.requesterId = id;
      this.getTasks();
    },
    onChangeUserKeyword(name) {
      this.userKeyword = name;
      this.getTasks();
    },
    onChangeStartDate(id) {
      this.startDate = id;
      this.startDateDialog = false;
      this.getTasks();
    },
    onChangeStart() {
      this.startDate = null;
      this.startDateDialog = false;
      this.getTasks();
    },
    onChangeEndDate(id) {
      this.endDate = id;
      this.endDateDialog = false;
      this.getTasks();
    },
    onChangeEnd() {
      this.endDate = null;
      this.endDateDialog = false;
      this.getTasks();
    },
    onChangeKeyword(id) {
      this.keyword = id;
      this.getTasks();
    },
    async getTasks() {
      const requestConfig = {
        url: "/tasks",
        method: "GET",
        params: {
          page: this.currentPage,
          priority: this.priorityId,
          status: this.statusId,
          categoryId: this.categoryId,
          tagId: this.tagId,
          requestedUserId: this.requestedId,
          requesterUserId: this.requesterId,
          userKeyword: this.userKeyword,
          dateStart: this.startDate,
          dateEnd: this.endDate,
          isPerfect: true,
          keyword: this.keyword,
          attribute: this.attribute,
          assignBoolean: 1,
          order: this.isOrder,
        },
      };
      console.log(requestConfig);
      myAxios(requestConfig).then((res) => {
        this.currentPage = 1;
        this.taskData = res.data;
      });
    },
  },
  async mounted() {
    this.isLoading = true;
    this.getTasks();
    await this.$store.dispatch("getUsers");
    this.$store.dispatch("getCategories");
    this.$store.dispatch("getTags");
    this.isLoading = false;
  },
};
</script>

<style lang="scss" scoped>
.red {
  background-color: red;
  color: white;
}
.blue {
  background-color: blue;
  color: white;
}
::v-deep {
  .v-input--radio-group__input .red--text {
    .theme--light.v-label {
      color: red;
    }
  }
  .v-input--radio-group__input .orange--text {
    .theme--light.v-label {
      color: orange;
    }
  }
  .v-input--radio-group__input .light-green--text {
    .theme--light.v-label {
      color: yellowgreen;
    }
  }
  .v-input--radio-group__input .green--text {
    .theme--light.v-label {
      color: green;
    }
  }
  .v-input--radio-group__input .blue--text {
    .theme--light.v-label {
      color: blue;
    }
  }
  .v-input--radio-group__input .amber--text {
    .theme--light.v-label {
      color: #ffc107;
    }
  }
  .v-btn-toggle:not(.v-btn-toggle--dense) .v-btn.v-btn.v-size--default {
    height: 40px;
    border-radius: 10%;
    background-color: #fff;
  }
  .v-select__selections {
    max-height: 40px;
  }
  .v-select__selection--comma:first-child {
    display: block;
  }
  .v-select__selection--comma {
    display: none;
  }
}
</style>